import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Дешевий ремонт авто
			</title>
			<meta name={"description"} content={"Економічне рішення для вашого автомобіля"} />
			<meta property={"og:title"} content={"Дешевий ремонт авто"} />
			<meta property={"og:description"} content={"Економічне рішення для вашого автомобіля"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="linkBox" />
			<Override slot="box4" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-8">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 50px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 15px 0px"
					font="--headline3"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
					sm-font="normal 600 28px/1.2 --fontFamily-sans"
					sm-text-align="left"
					sm-width="100%"
				>
					Спектр наших послуг розроблений, щоб задовольнити всі ваші автомобільні потреби, гарантуючи, що ваш автомобіль завжди буде в ідеальному стані.{" "}
					<br />
					<br />
					Наші послуги включають:
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/156605-manutencao-do-carro-o-que-voce-precisa-para-dirigir-com-seguranca.jpg?v=2024-02-12T09:42:46.446Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Профілактичне обслуговування
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Щоб уникнути майбутніх проблем, ми пропонуємо повні перевірки, заміну масла, перевірку freio тощо.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/2-2.jpg?v=2024-02-12T09:42:20.835Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Генеральний ремонт
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Від проблем з двигуном до несправностей електрики наша команда готова виконати будь-який ремонт.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Заміна шин
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Ми постачаємо та встановлюємо якісні шини, забезпечуючи безпеку та продуктивність вашого автомобіля.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/4-1.jpg?v=2024-02-12T09:42:20.836Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Діагностичні послуги
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Ми використовуємо передові технології для швидкої діагностики проблем, заощаджуючи ваш час і гроші.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/3-2.jpg?v=2024-02-12T09:42:20.820Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Налаштування та оновлення
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="center"
						>
							Для тих, хто хоче більше від своїх автомобілів, ми пропонуємо послуги з налаштування та покращення продуктивності.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/2-1.jpg?v=2024-02-12T09:42:20.837Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Незалежно від ваших потреб ReparAuto Baratos тут, щоб допомогти. Зв’яжіться з нами, щоб запланувати обслуговування або дізнатися більше про те, як ми можемо задовольнити ваші автомобільні потреби.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-5" lg-padding="2rem 0 1rem 0">
			<Override
				slot="SectionContent"
				padding="0px 20% 0px 0px"
				lg-padding="0px 30% 0px 0px"
				md-padding="0px 0 0px 0px"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0 40px 0px"
				font="normal 600 72px/1.2 --fontFamily-sans"
				color="#090e28"
				letter-spacing="4px"
				md-font="normal 600 56px/1.2 --fontFamily-sans"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Телефонуйте нам 0412240249
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#334455" sm-margin="0px 0px 25px 0px">
				Наша команда прагне надати потрібні вам рішення за якістю та ціною, на які ви заслуговуєте. Завітайте до нас або зв’яжіться з нами через наші канали обслуговування.{" "}
			</Text>
		</Section>
		<Components.Header>
			<Override slot="link3">
				0412240249
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});